import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    props:true
  },
  {
    path: "/schedule",
    name: "schedule",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/schedule.vue"),
    props: true
  },
  {
    path: "/media-accreditation-form",
    name: "media-accreditation-form",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mediaForm.vue"),
    props: true
  },
  {
    path: "/players",
    name: "players",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/players.vue"),
      props:true
    },
  {
    path: "/playerprofile/:refno",
    name: "playerprofile",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/playerprofile.vue"),
    props: true
  },
  {
    path: "/news",
    name: "News",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/news.vue")
  },
  {
    path: "/morenews",
    name: "morenews",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/viewallNews.vue")
  },
  // {
  //   path: "/morenews",
  //   name: "morenews",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ "../views/news.vue")
  // },
  {
    path: "/morenews/:id",
    name: "morenewstory",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsStory.vue"),
    props: true
  },
  {
    path: "/faq",
    name: "faq",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited 
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
      props:true
  },
  {
    path: "/tickets",
    name: "tickets",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited tickets.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ticket.vue"),
      props:true
  },
  {
    path: "/anti-doping",
    name: "anti-doping",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue"),
      props:true
  },
  {
    path: "/contact-us",
    name: "contact-us",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/covid-protocols",
    name: "covid-protocols",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/about-us",
    name: "about-us",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/partners-and-sponsors",
    name: "partners-and-sponsors",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/beat-the-pro",
    name: "beat-the-pro",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/energy-force",
    name: "energy-force",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/beat-the-pro-ocs",
    name: "beat-the-pro-ocs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/single.vue")
  },
  {
    path: "/partners-and-sponsors/:slug",
    name: "partner",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/partner.vue"),
    props: true
  },
  {
    path: "/reports",
    name: "reports",
    component: () =>
      import("../views/reports.vue"),
    props: true
  },
  {
    path: "/qschool",
    name: "qschool",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/qschool.vue")
  },
  {
    path: "/vusi-ngubeni",
    name: "vusi-ngubeni",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/schoolpage.vue")
  },
  {
    path: "/sa-open",
    name: "sa-open",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/schoolpage.vue")
  },
  {
    path: "/sunshine-development-tour",
    name: "sunshine-development-tour",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/schoolpage.vue")
  },
  {
    path: "/tournament",
    name: "tournament",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tournament.vue"),
    props: true
  },
  {
    path: "/ticket-open",
    name: "ticket-open",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ticketopen.vue"),
    props: true
  },
  {
    path: "/the-papwa-swegolum-class",
    name: "the-papwa-swegolum-class",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/papwaClass.vue"),
    props: true
  },
  {
    path: "/oom",
    name: "oom",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/oom.vue"),
    props: true
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/search.vue"),
    props: true
  },
  {
    path: "/the-score",
    name: "thescore",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news.vue"),
    props: true
  },
  {
    path: "/blue-label",
    name: "blue-label",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/bldtNews.vue"),
    props: true
  },
  {
    path: "/fanZone",
    name: "fanZone",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../ocs/fanzone/views/fanZone.vue")
  },
  {
    path: "/myplayers",
    name: "myplayers",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../ocs/fanzone/views/myplayers.vue")
  },
  {
    path: "/myprofile",
    name: "myprofile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../ocs/fanzone/views/myprofile.vue")
  },
  {
    path: "/videohub",
    name: "videohub",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../ocs/fanzone/views/videohub.vue")
  },
  {
    path: "/the-score/:id",
    name: "thescorestory",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsStory.vue"),
    props: true
  },
  {
    path: "/:id",
    name: "news story",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsStory.vue"),
    props: true
  },
  { 
    path: '/:catchAll(.*)', 
    name: 'NotFound'
  }
];

const router = new VueRouter({
  routes,
  mode:'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
});

export default router;