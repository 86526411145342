<template>
  <div class="reports">
    <div class="main">
      <template v-if="loading === false">
        <b-tabs class="tabsLi" :class="{ noborder: tmparamsMini != null }">
          <div v-if="home == false">
            <b-row>
              <b-col
                v-if="tmparamsMini.multi_course === 'Y'"
                class="col-md-6 multi"
              >
                <span
                  class="multiList"
                  v-for="(multi, index) in tmparamsMini.courses.courses_entry"
                  :key="index"
                >
                  <span :class="multi.course_X_colour" class="ballpin">•</span
                  ><span class="multiName">{{ multi.course_X_id }}</span>
                </span>
              </b-col>
            </b-row>
          </div>
          <template v-if="teamEvent === 'Y'">
            <template v-if="data.hide_gross_sb == 'Y'">
              <b-tab
                title="Leaderboard"
                active
                :title-item-class="{ mytab: teamEvent === 'N' }"
              >
                <template v-if="data.stableford == 'Y'">
                  <ScoreTeam
                    :data="data"
                    :title="title"
                    :home="home"
                    :config="config"
                    :team="teamEvent"
                    :match="tmparamsMini.match_play"
                    :code="code"
                    :season="season"
                  />
                </template>
                <template v-else>
                  <ReportTable
                    :data="data"
                    :title="title"
                    :home="home"
                    :config="config"
                    :team="teamEvent"
                    :match="tmparamsMini.match_play"
                    :code="code"
                    :season="season"
                  />
                </template>
              </b-tab>
            </template>
            <template v-else>
              <b-tab title="Leaderboard" active title-item-class="MultiTab">
                <ReportTable
                  :data="data"
                  :title="title"
                  :home="home"
                  :config="config"
                  team="N"
                  :match="tmparamsMini.match_play"
                  :code="code"
                  :season="season"
                />
              </b-tab>
              <b-tab title="Team" title-item-class="MultiTab">
                <template v-if="data.stableford == 'Y'">
                  <ScoreTeam
                    :data="data"
                    :title="title"
                    :home="home"
                    :config="config"
                    :team="teamEvent"
                    :match="tmparamsMini.match_play"
                    :code="code"
                    :season="season"
                  />
                </template>
                <template v-else>
                  <ReportTable
                    :data="data"
                    :title="title"
                    :home="home"
                    :config="config"
                    :team="teamEvent"
                    :match="tmparamsMini.match_play"
                    :code="code"
                    :season="season"
                  />
                </template>
              </b-tab>
            </template>
          </template>

          <!-- <template v-else-if="multiTourns == 'Y'">
            <b-tab :title="data.past_tourns.past_tourns_entry.name" active :title-item-class="{mytab : teamEvent === 'N'}">
              <ReportTable
                :data="data"
                :title="title"
                :home="home"
                :config="config"
                team="N"
                :match="tmparamsMini.match_play"
                :code="code"
                :season="season"
              />
            </b-tab>
          </template> -->

          <template v-else>
            <b-tab
              title="Leaderboard"
              active
              :title-item-class="{ mytab: teamEvent === 'N' }"
            >
              <ReportTable
                :data="data"
                :title="title"
                :home="home"
                :config="config"
                team="N"
                :match="tmparamsMini.match_play"
                :code="code"
                :season="season"
              />
            </b-tab>
          </template>
        </b-tabs>
        <!-- <ReportTable :config="config" :data="data" :home="home" :title="title" :report="report" :season='season' :code='course' :team="teamEvent" :match="tm_params.match_play"/> -->
        <template v-if="multiTourns == 'Y'">
          <div class="FullListingBtn" v-if="this.home === true">
            <b-button
              :to="{
                name: 'reports',
                query: {
                  url: report_url_for_report_page,
                  id: season,
                  code: course,
                  title: reports_title
                }
              }"
            >
              View Full Leaderboard
              <span class="rightArrow">
                <font-awesome-icon
                  class="icon rightArrow"
                  :icon="['fa', 'chevron-right']"
                />
              </span>
            </b-button>
          </div>
        </template>
        <template v-else>
          <div class="FullListingBtn" v-if="this.home === true">
            <b-button
              :to="{
                name: 'reports',
                query: {
                  url: report,
                  id: season,
                  code: course,
                  title: title
                }
              }"
            >
              View Full Leaderboard
              <span class="rightArrow">
                <font-awesome-icon
                  class="icon rightArrow"
                  :icon="['fa', 'chevron-right']"
                />
              </span>
            </b-button>
          </div>
        </template>
      </template>
      <template v-if="loading === true">
        <div class="spinnerMiniResults">
          <h3>Loading</h3>
          <b-spinner label="Spinning"></b-spinner>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import ReportTable from "@/components/reportTable.vue";
import { ScoreTeam } from "@ocs-software/vue-ocs";

export default {
  name: "miniLeader",
  props: [
    "season",
    "course",
    "report",
    "title",
    "config",
    "tmparamsMini",
    "team",
    "apiClass",
    "multiTourns"
  ],
  components: {
    ReportTable,
    ScoreTeam
  },
  data() {
    return {
      tm_params: [],
      data: [],
      home: true,
      loading: false,
      polling: null,
      report_url_for_report_page: this.report,
      reports_title: this.title
    };
  },
  methods: {
    pollData() {
      this.polling = setInterval(() => {
        // console.log("Run refresh");
      }, 30000);
    },
    async updateStuff() {
      try {
        // First call, await for call to return
        // let first_call = await apiCall.tmParamsYear(this.season);
        // this.tm_params = first_call.data;
        // this.loading = false;
        // Second call, await for call to return

        if (this.multiTourns == "Y") {
          let first_call = await apiCall.report(this.reportTitle);
          const ticx_url =
            this.config.VUE_APP_API +
            "sst" +
            "/cache/" +
            this.apiClass +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-tmticx.json?randomadd=" +
            new Date().getTime();
          let ticx_call = await apiCall.report(ticx_url);
          this.data = first_call.data;

          if (ticx_call && ticx_call.data.reports) {
            const reports = ticx_call.data.reports.reports_entry;
            if (!Array.isArray(reports)) {
              console.log('isNot Array')
            } else{
              reports.forEach(element => {
                if (element.report_url.indexOf("tmentry") !== -1) {
                  this.report_url_for_report_page = element.report_url;
                  this.reports_title = element.report_title;
                  // this.updateStuff();
                }
                if (element.report_url.indexOf("tmscore") !== -1) {
                  this.report_url_for_report_page = element.report_url;
                  this.reports_title = element.report_title;
                }
                if (element.report_url.indexOf("tmresult") !== -1) {
                  this.report_url_for_report_page = element.report_url;
                  this.reports_title = element.report_title;
                  // this.updateStuff();
                }
              });
            }
          }
        } else {
          let first_call = await apiCall.report(this.reportTitle);
          this.data = first_call.data;
          this.loading = false;
        }
        this.loading = false;
      } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
      }
    }
  },
  watch: {
    reportTitle: function() {
      this.updateStuff();
      this.loading = true;
    }
  },
  computed: {
    teamEvent: function(team) {
      var array = this.data;
      if (array["team_scores"] !== undefined) team = "Y";
      else team = "N";
      return team;
    },
    reportTitle: function(url) {
      if (this.report.indexOf("tmentry") !== -1) {
        url =
          process.env.VUE_APP_API +
          "sst" +
          "/cache/" +
          this.apiClass +
          "/" +
          this.season +
          "/" +
          this.season +
          "-" +
          this.course +
          "-entryw-entries.json?randomadd=" +
          new Date().getTime();
      } else if (this.report.indexOf("tmentry") !== -1) {
        url =
          process.env.VUE_APP_API +
          "sst" +
          "/cache/" +
          this.apiClass +
          "/" +
          this.season +
          "/" +
          this.season +
          "-" +
          this.course +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      }  else {
        if (this.report.indexOf("tmscore") !== -1) {
          var subRep2 = this.report.lastIndexOf("params=") + 7;
          var indNext2 = this.report.indexOf("~", subRep2);
          var repParams = this.report.substr(subRep2, indNext2 - subRep2);

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              process.env.VUE_APP_API +
              "sst" +
              "/cache/" +
              this.apiClass +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-" +
              // repParams +
              "latest" +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              process.env.VUE_APP_API +
              "sst" +
              "/cache/" +
              this.apiClass +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.report.indexOf("tmlsmon") !== -1) {
          {
            url =
              process.env.VUE_APP_API +
              "sst" +
              "/cache/" +
              this.apiClass +
              "/" +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.report.indexOf("tmdraw") !== -1) {
          var subRep = this.report.lastIndexOf("round=") + 6;
          var indNext = this.report.indexOf("~", subRep);
          var drawRnd = this.report.substr(subRep, indNext - subRep);
          url =
            process.env.VUE_APP_API +
            "sst" +
            "/cache/" +
            this.apiClass +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.report.indexOf("tmresult") !== -1) {
          var subRep1 = this.report.lastIndexOf("result=") + 7;
          var indNext1 = this.report.indexOf("~", subRep1);
          var resNum1 = this.report.substr(subRep1, indNext1 - subRep1);
          // console.log("resNum === ");
          // console.log(resNum1);
          url =
            process.env.VUE_APP_API +
            "sst" +
            "/cache/" +
            this.apiClass +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            process.env.VUE_APP_API +
            "sst" +
            "/cache/" +
            this.apiClass +
            "/" +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    }
  },
  created() {
    setInterval(this.updateStuff, 120000);
  },
  async mounted() {
    try {
      // First call, await for call to return
      // let first_call = await apiCall.tmParamsYear(this.season);
      // this.tm_params = first_call.data;
      // this.loading = false;
      // Second call, await for call to return
      let first_call = await apiCall.report(this.reportTitle);
      this.data = first_call.data;
      this.pollData();
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>

<style scoped>
::v-deep div#entries {
    height: 216px;
    display: block;
    overflow: scroll;
}
::v-deep .entries .LeftAlign {
  text-align: left;
  padding-left: 20px;
}
::v-deep .entries th:first-child {
  text-align: center;
  display: none;
}
::v-deep .entries td.posnum {
  padding: 5px;
  /* padding-top: 4px; */
  padding-top: 7px;
  padding-bottom: 7px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
  color: #6c6c6c;
  font-weight: bold;
  display: none;
}
::v-deep .results .title {
  display: none;
}
::v-deep .scoresOnly .title {
  display: none;
}
::v-deep .draw .title {
  display: none;
}
::v-deep .entries .title {
  display: none;
}
::v-deep .teamscoresOnly .title {
  display: none;
}
::v-deep li.nav-item.MultiTab {
  width: 50%;
  display: inline-block;
  text-align: center;
}
::v-deep .nav-tabs .nav-link.active {
  border-radius: 0 !important;
  background-color: #026638 !important;
  color: #fff !important;
  padding: 5px 20px;
  font-weight: bold;
  text-align: center !important;
}
::v-deep .noborder > div > ul {
  border-radius: 0px;
}
::v-deep li.nav-item.mytab > a {
  background-color: rgb(33 66 50);
  color: #fff !important;
}
::v-deep li.nav-item.mytab > .nav-link.active {
  background-color: #f2af15;
}
::v-deep ul#__BVID__114__BV_tab_controls_ > li.nav-item {
  border-radius: 10px;
  display: inline-grid;
  width: 50%;
  text-align: center;
  background-color: #f2af15;
}
span.rightArrow {
  position: absolute;
  right: 12px;
}
.B {
  color: #333333;
}
.Y {
  color: #d8db22;
}
.C {
  color: #1e90ff;
}
.R {
  color: #bb0000;
}
.G {
  color: #3c9933;
}
.O {
  color: #e07020;
}
span.multiName {
  color: #016837;
  font-size: 15pt;
  font-weight: 700;
}
.ballpin {
  position: relative;
  font-size: 65px !important;
  line-height: 0px !important;
  vertical-align: middle;
  top: -4px;
}
.spinnerMiniResults {
  text-align: center;
}
::v-deep .catD {
  background-color: #214232;
  color: #ffffff;
  font-size: 12pt;
  font-weight: 600;
}
::v-deep tr:nth-child(even) {
  /* border-top: 1px solid #fff; */
  border-top: 0px solid #fff;
  background-color: rgba(0, 0, 0, 0.05);
}
::v-deep tr:nth-child(odd) {
  /* border-top: 1px solid #fff; */
  border-top: 0px solid #fff;
  /* background-color: rgba(0,0,0,.05); */
}
::v-deep td > a {
  color: #000 !important;
}
::v-deep td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
  color: black;
  font-weight: bold;
}
::v-deep td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 0px solid #ddd;
  border-right: none !important;
  cursor: pointer;
  font-size: 15px;
  text-align: center;
}
.FullListingBtn {
  text-align: left;
  display: block;
  margin: auto;
  background: #006737;
  border: none;
  color: #fff;
  border-radius: 0;
  padding: 5px 20px;
  /* width: 200px;
  margin-top: 1.5em; */
  margin-bottom: 0.5em;
  text-transform: capitalize;
  font-size: 10px;
}
.FullListingBtn > a {
  color: #fff !important;
  text-decoration: none;
  width: 100%;
  text-align: left;
}
.FullListingBtn:hover {
  text-align: left;
  display: block;
  margin: auto;
  background: #204232;
  border: none;
  color: #fff;
  border-radius: 0;
  padding: 5px 20px;
  /* width: 200px;
  margin-top: 1.5em; */
  margin-bottom: 0.5em;
  text-transform: capitalize;
  font-size: 10px;
}
.btn {
  background-color: transparent;
  border: 0;
}
.btn:hover {
  background-color: transparent;
  border: 0;
}
::v-deep th {
  position: sticky;
  /* top: 0; */
  background-color: #214232 !important;
  vertical-align: bottom;
  border-bottom: 0px solid #ddd;
  font-weight: 100;
  color: white;
  font-size: 13px;
  text-transform: capitalize;
  position: sticky;
  position: -webkit-sticky;
  /* top: -1px; */
  color: #ffffff !important;
  text-align: center;
  padding: 0.75rem;
}
@media only screen and (max-width: 990px) {
  span.rightArrow {
    position: absolute;
    right: 53px;
  }
}
</style>
