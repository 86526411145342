<template>
  <div class="">
    <div class="titleAndSpon">
      <span class="spanTitleAndSpon">
        <template v-if="Rookieevent_sponsor_logo !== ''">
          <img class="eventSponLogo" :src="Rookieevent_sponsor_logo">
        </template>
        <template v-else>
          <h1 class="orderOfMerit">{{full_name}}</h1>
        </template>
      </span>
    </div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      fade
      indicators
      :interval="5000"
    >
      <b-carousel-slide
        img-blank
        img-src="https://picsum.photos/1024/480/?image=52"
      >
        <b-row>
          <b-col
            v-for="(oomSlider, index) in rookie.slice(0, 1)"
            :key="index"
            class="cardWidth"
          >
            <!-- <div class="positionOOM">
              <div class="posNum">1</div>
              <div class="BlankPos"></div>
            </div> -->
            <div>
              <b-row class="noPad">
                <b-col cols="12" lg="1" class="pos">
                  <span class="posSpan">1</span>
                </b-col>
                <b-col cols="12" lg="11" class="blankPos"></b-col>
              </b-row>
            </div>
            <b-row>
              <template v-if="isMobile(true)">
                <b-col
                  cols="12"
                  lg="3"
                  sm="12"
                >
                <img :src=" DEFIMAGESAMS3 +
                      'sst' +
                      '/' +
                      'media/photos/' +
                      oomSlider.playerno +
                      '.jpg'">
                </b-col>
              </template>
              <template v-else>
                <b-col
                  cols="12"
                  lg="3"
                  sm="12"
                  class="bkimage"
                  v-bind:style="{
                    'background-image':
                      'url(' +
                      DEFIMAGESAMS3 +
                      'sst' +
                      '/' +
                      'media/photos/' +
                      oomSlider.playerno +
                      '.jpg' +
                      ')',
                  }"
                  img-alt="Card image"
                  img-left
                >
                </b-col>
              </template>
              <b-col
                cols="12"
                lg="9"
                sm="12"
              >
                <b-card class="mb-3">
                  <b-card-text v-if="isMobile(true)">
                    <div class="oomPlayerInfo">
                      <span class="natBorder">
                        <img
                          :class="flag"
                          :src="
                            (config.VUE_APP_FLAG_URL + oomSlider.nationality)
                              | lowercase
                          "
                        />
                      </span>
                      <div>
                        <a
                          class="nameSlider"
                          :href="'/playerprofile/' + oomSlider.playerno"
                        >
                          <h2 class="playerName">{{ oomSlider.name }}</h2>
                        </a>
                        <p class="statsYear">Current Season Tournament Stats</p>
                      </div>
                    </div>
                    <div>
                      <b-row class="tournsOOM">
                        <div class="sectionOne">
                          <b-col>
                            <div class="statBlock">
                              <span class="statsTitle">Tournaments Played</span>
                              <span class="statsData">{{
                                oomSlider.tournaments_played
                              }}</span>
                            </div>
                          </b-col>
                          <b-col>
                            <div class="statBlock">
                              <span class="statsTitle">Tournament Wins</span>
                              <span class="statsData">{{
                                oomSlider.wins
                              }}</span>
                            </div>
                          </b-col>
                        </div>
                        <div class="sectionTwo">
                          <b-col>
                            <div class="statBlock">
                              <span class="statsTitle">Top Ten Finishes</span>
                              <span class="statsData">{{
                                oomSlider.top_ten
                              }}</span>
                            </div>
                          </b-col>
                          <b-col>
                            <div class="statBlock">
                              <span class="statsTitle">Lowest Round</span>
                              <span class="statsData">{{
                                oomSlider.low_round
                              }}</span>
                            </div>
                          </b-col>
                        </div>
                      </b-row>
                    </div>
                    <a :href="'/oom?id=' + Year">
                      <div class="FullListingBtn">
                        <v-btn> See Full Ranking List </v-btn>
                      </div>
                    </a>
                  </b-card-text>
                  <b-card-text v-else>
                    <div class="oomPlayerInfo">
                      <span class="natBorder">
                        <img
                          :class="flag"
                          :src="
                            (config.VUE_APP_FLAG_URL + oomSlider.nationality)
                              | lowercase
                          "
                        />
                      </span>
                      <div>
                        <a
                          class="nameSlider"
                          :href="'/playerprofile/' + oomSlider.playerno"
                        >
                          <h2 class="playerName">{{ oomSlider.name }}</h2>
                        </a>
                        <p class="statsYear">Current Season Tournament Stats</p>
                      </div>
                    </div>
                    <div>
                      <b-row class="tournsOOM">
                        <div class="sectionOne">
                          <b-col>
                            <div class="statBlock">
                              <span class="statsData">{{
                                oomSlider.tournaments_played
                              }}</span>
                              <span class="statsTitle">Tournaments Played</span>
                            </div>
                          </b-col>
                          <b-col>
                            <div class="statBlock">
                              <span class="statsData">{{
                                oomSlider.wins
                              }}</span>
                              <span class="statsTitle">Tournament Wins</span>
                            </div>
                          </b-col>
                        </div>
                        <div class="sectionTwo">
                          <b-col>
                            <div class="statBlock">
                              <span class="statsData">{{
                                oomSlider.top_ten
                              }}</span>
                              <span class="statsTitle">Top Ten Finishes</span>
                            </div>
                          </b-col>
                          <b-col>
                            <div class="statBlock">
                              <span class="statsData">{{
                                oomSlider.low_round
                              }}</span>
                              <span class="statsTitle">Lowest Round</span>
                            </div>
                          </b-col>
                        </div>
                      </b-row>
                    </div>
                    <a class="fullList" :href="'/oom?id=' + Year + '&value=RK'">
                      <div class="FullListingBtn">
                        <v-btn> See Full Ranking List </v-btn>
                      </div>
                    </a>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-carousel-slide>
      <!-- <b-carousel-slide
        img-blank
        img-src="https://picsum.photos/1024/480/?image=52"
      >
        <b-row>
          <b-col
            v-for="(oomSlider, index) in sliderOom.slice(1, 2)"
            :key="index"
            class="cardWidth"
          >
            <div>
              <b-row class="noPad">
                <b-col cols="12" lg="1" class="pos">
                  <span class="posSpan">2</span>
                </b-col>
                <b-col cols="12" lg="11" class="blankPos"></b-col>
              </b-row>
            </div>
            <b-row>
              <b-col
                cols="12"
                lg="3"
                sm="12"
                class="bkimage"
                v-bind:style="{
                  'background-image':
                    'url(' +
                    DEFIMAGESAMS3 +
                    '/' +
                    'sst' +
                    '/' +
                    'media/photos/' +
                    oomSlider.playerno +
                    '.jpg' +
                    ')',
                }"
                img-alt="Card image"
                img-left
              >
              </b-col>
              <b-col
                cols="12"
                lg="9"
                sm="12"
              >
                <b-card class="mb-3">
                  <b-card-text v-if="isMobile(true)">
                    <div class="oomPlayerInfo">
                      <span class="natBorder">
                        <img
                          :class="flag"
                          :src="
                            (config.VUE_APP_FLAG_URL + oomSlider.nationality)
                              | lowercase
                          "
                        />
                      </span>
                      <div>
                        <a
                          class="nameSlider"
                          :href="'/playerprofile/' + oomSlider.playerno"
                        >
                          <h2 class="playerName">{{ oomSlider.name }}</h2>
                        </a>
                        <p class="statsYear">Current Season Tournament Stats</p>
                      </div>
                    </div>
                    <div>
                      <b-row class="tournsOOM">
                        <div class="sectionOne">
                          <b-col>
                            <div class="statBlock">
                              <span class="statsTitle">Tournaments Played</span>
                              <span class="statsData">{{
                                oomSlider.tournaments_played
                              }}</span>
                            </div>
                          </b-col>
                          <b-col>
                            <div class="statBlock">
                              <span class="statsTitle">Tournament Wins</span>
                              <span class="statsData">{{
                                oomSlider.wins
                              }}</span>
                            </div>
                          </b-col>
                        </div>
                        <div class="sectionTwo">
                          <b-col>
                            <div class="statBlock">
                              <span class="statsTitle">Top Ten Finishes</span>
                              <span class="statsData">{{
                                oomSlider.top_ten
                              }}</span>
                            </div>
                          </b-col>
                          <b-col>
                            <div class="statBlock">
                              <span class="statsTitle">Lowest Round</span>
                              <span class="statsData">{{
                                oomSlider.low_round
                              }}</span>
                            </div>
                          </b-col>
                        </div>
                      </b-row>
                    </div>
                    <a :href="'/oom?id=' + Year">
                      <div class="FullListingBtn">
                        <v-btn> See Full Ranking List </v-btn>
                      </div>
                    </a>
                  </b-card-text>
                  <b-card-text v-else>
                    <div class="oomPlayerInfo">
                      <span class="natBorder">
                        <img
                          :class="flag"
                          :src="
                            (config.VUE_APP_FLAG_URL + oomSlider.nationality)
                              | lowercase
                          "
                        />
                      </span>
                      <div>
                        <a
                          class="nameSlider"
                          :href="'/playerprofile/' + oomSlider.playerno"
                        >
                          <h2 class="playerName">{{ oomSlider.name }}</h2>
                        </a>
                        <p class="statsYear">Current Season Tournament Stats</p>
                      </div>
                    </div>
                    <div>
                      <b-row class="tournsOOM">
                        <div class="sectionOne">
                          <b-col>
                            <div class="statBlock">
                              <span class="statsData">{{
                                oomSlider.tournaments_played
                              }}</span>
                              <span class="statsTitle">Tournaments Played</span>
                            </div>
                          </b-col>
                          <b-col>
                            <div class="statBlock">
                              <span class="statsData">{{
                                oomSlider.wins
                              }}</span>
                              <span class="statsTitle">Tournament Wins</span>
                            </div>
                          </b-col>
                        </div>
                        <div class="sectionTwo">
                          <b-col>
                            <div class="statBlock">
                              <span class="statsData">{{
                                oomSlider.top_ten
                              }}</span>
                              <span class="statsTitle">Top Ten Finishes</span>
                            </div>
                          </b-col>
                          <b-col>
                            <div class="statBlock">
                              <span class="statsData">{{
                                oomSlider.low_round
                              }}</span>
                              <span class="statsTitle">Lowest Round</span>
                            </div>
                          </b-col>
                        </div>
                      </b-row>
                    </div>
                    <a class="fullList" :href="'/oom?id=' + Year">
                      <div class="FullListingBtn">
                        <v-btn> See Full Ranking List </v-btn>
                      </div>
                    </a>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-carousel-slide>
      <b-carousel-slide
        img-blank
        img-src="https://picsum.photos/1024/480/?image=52"
      >
        <b-row>
          <b-col
            v-for="(oomSlider, index) in sliderOom.slice(2, 3)"
            :key="index"
            class="cardWidth"
          >
            <div>
              <b-row class="noPad">
                <b-col cols="12" lg="1" class="pos">
                  <span class="posSpan">3</span>
                </b-col>
                <b-col cols="12" lg="11" class="blankPos"></b-col>
              </b-row>
            </div>
            <b-row>
              <b-col
                cols="12"
                lg="3"
                sm="12"
                class="bkimage"
                v-bind:style="{
                  'background-image':
                    'url(' +
                    DEFIMAGESAMS3 +
                    '/' +
                    'sst' +
                    '/' +
                    'media/photos/' +
                    oomSlider.playerno +
                    '.jpg' +
                    ')',
                }"
                img-alt="Card image"
                img-left
              >
              </b-col>
              <b-col
                cols="12"
                lg="9"
                sm="12"
              >
                <b-card class="mb-3">
                  <b-card-text v-if="isMobile(true)">
                    <div class="oomPlayerInfo">
                      <span class="natBorder">
                        <img
                          :class="flag"
                          :src="
                            (config.VUE_APP_FLAG_URL + oomSlider.nationality)
                              | lowercase
                          "
                        />
                      </span>
                      <div>
                        <a
                          class="nameSlider"
                          :href="'/playerprofile/' + oomSlider.playerno"
                        >
                          <h2 class="playerName">{{ oomSlider.name }}</h2>
                        </a>
                        <p class="statsYear">Current Season Tournament Stats</p>
                      </div>
                    </div>
                    <div>
                      <b-row class="tournsOOM">
                        <div class="sectionOne">
                          <b-col>
                            <div class="statBlock">
                              <span class="statsTitle">Tournaments Played</span>
                              <span class="statsData">{{
                                oomSlider.tournaments_played
                              }}</span>
                            </div>
                          </b-col>
                          <b-col>
                            <div class="statBlock">
                              <span class="statsTitle">Tournament Wins</span>
                              <span class="statsData">{{
                                oomSlider.wins
                              }}</span>
                            </div>
                          </b-col>
                        </div>
                        <div class="sectionTwo">
                          <b-col>
                            <div class="statBlock">
                              <span class="statsTitle">Top Ten Finishes</span>
                              <span class="statsData">{{
                                oomSlider.top_ten
                              }}</span>
                            </div>
                          </b-col>
                          <b-col>
                            <div class="statBlock">
                              <span class="statsTitle">Lowest Round</span>
                              <span class="statsData">{{
                                oomSlider.low_round
                              }}</span>
                            </div>
                          </b-col>
                        </div>
                      </b-row>
                    </div>
                    <a :href="'/oom?id=' + Year">
                      <div class="FullListingBtn">
                        <v-btn> See Full Ranking List </v-btn>
                      </div>
                    </a>
                  </b-card-text>
                  <b-card-text v-else>
                    <div class="oomPlayerInfo">
                      <span class="natBorder">
                        <img
                          :class="flag"
                          :src="
                            (config.VUE_APP_FLAG_URL + oomSlider.nationality)
                              | lowercase
                          "
                        />
                      </span>
                      <div>
                        <a
                          class="nameSlider"
                          :href="'/playerprofile/' + oomSlider.playerno"
                        >
                          <h2 class="playerName">{{ oomSlider.name }}</h2>
                        </a>
                        <p class="statsYear">Current Season Tournament Stats</p>
                      </div>
                    </div>
                    <div>
                      <b-row class="tournsOOM">
                        <div class="sectionOne">
                          <b-col>
                            <div class="statBlock">
                              <span class="statsData">{{
                                oomSlider.tournaments_played
                              }}</span>
                              <span class="statsTitle">Tournaments Played</span>
                            </div>
                          </b-col>
                          <b-col>
                            <div class="statBlock">
                              <span class="statsData">{{
                                oomSlider.wins
                              }}</span>
                              <span class="statsTitle">Tournament Wins</span>
                            </div>
                          </b-col>
                        </div>
                        <div class="sectionTwo">
                          <b-col>
                            <div class="statBlock">
                              <span class="statsData">{{
                                oomSlider.top_ten
                              }}</span>
                              <span class="statsTitle">Top Ten Finishes</span>
                            </div>
                          </b-col>
                          <b-col>
                            <div class="statBlock">
                              <span class="statsData">{{
                                oomSlider.low_round
                              }}</span>
                              <span class="statsTitle">Lowest Round</span>
                            </div>
                          </b-col>
                        </div>
                      </b-row>
                    </div>
                    <a class="fullList" :href="'/oom?id=' + Year">
                      <div class="FullListingBtn">
                        <v-btn> See Full Ranking List </v-btn>
                      </div>
                    </a>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-carousel-slide> -->
    </b-carousel>
  </div>
</template>

<script>
import axios from 'axios';
// import apiCall from "@/services/api.js";

export default {
  name: "Rookie OOM",
  props: ["season", "Year", "config", "event_sponsor_logo" , 'DEFIMAGESAMS3'],
  data() {
    return {
      // sliderOom: [],
      rookie:[],
      full_name:[],
      flag: "flag",
    };
  },

  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
  methods: {
    isMobile() {
        if( screen.width <= 760 ) {
            return true;
        }
        else {
            return false;
        }
    },
    YearSeason: function () {
      axios
      .get(
        "https://api.euro.ocs-software.com/sst/cache/sst" +
          "/tmticx?field=Y&fields=seasons&fields=tm_params.season_code&randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.currentSeason = response.data.tm_params.season_code;
        this.Rookie();
      });
    },
    Rookie: function () {
      axios
      .get(
          'https://api.euro.ocs-software.com/sst/cache/sst/' + this.currentSeason + '/'  + this.currentSeason +  '-ooms-oom-RK.json' + '?randomaddRookie=' +
            new Date().getTime()
      )
      .then((response) => {
        this.rookie = response.data.oom.oom_entry;
        console.log("this.rookie")
        console.log(this.rookie)
        this.loading = false;
        this.Rookieevent_sponsor_logo = response.data.event_sponsor_logo;
        this.full_name = response.data.full_name
      });
    },
  },
  mounted() {
    this.YearSeason();
  }
};
</script>

<style scoped>
.eventSponLogo {
  margin: auto;
  display: block;
  width: 300px;
}
::v-deep .bkimage {
  position: relative;
  left: 4.3em;
  top: -3.4em;
  background-repeat: no-repeat;
  background-size: cover;
  height: 304px;
  background-position: center;
  /* border-bottom-left-radius: 10px; */
}
img.card-img-left {
  /* width: 500px; */
  width: 300px;
  border-bottom-left-radius: 10px;
}
a.fullList {
  text-decoration: none;
}
.spanTitleAndSpon {
  display: inline-flex;
  margin: inherit;
}
.titleAndSpon {
  margin: auto;
  display: flex;
  margin-top: -15px;
}
.eventSponLogo {
  /* width: 100px; */
  margin: auto;
  display: block;
  /* margin-top: -7px; */
  /* margin-right: 40px; */
}
h1.orderOfMerit {
  color: #6c6c6c;
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  padding-bottom: 25px;
  text-align: center;
}
a.nameSlider {
  text-decoration: none;
}
.posSpan {
  position: relative;
  top: 15px;
  font-size: 22px;
  font-weight: bold;
  left: -17px;
}
.noPad {
  margin-right: 0px;
  margin-left: 0px;
}
.pos {
  background: #f2af14;
  height: 60px;
  border-radius: 10px 0px 0px 10px;
  max-width: 9%;
}
.blankPos {
  background: #f2af14;
  position: relative;
  height: 10px;
  top: 0px;
  border-radius: 0px 0px 0px 0px;
}
.posYellow {
  /* background: #F2AF14; */
}
.sectionOne {
  display: contents;
}
.sectionTwo {
  display: contents;
}
.carousel-item {
  height: 450px;
}
.img-fluid {
  max-width: 100%;
  height: 450px;
}
::v-deep .carousel-caption {
  position: absolute;
  right: 0px;
  bottom: 20px;
  left: 0px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem 0rem 0.25rem 0.25rem;
  width: 95%;
  margin-left: 54px;
  margin-top: -3.4em;
}
.posNum {
  width: 100px;
  /* height: 50px; */
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  border-radius: 10px 0px 0px 10px;
}
.BlankPos {
  width: 100%;
  display: inline-block;
  height: 25px;
  background: #fff;
}
.positionOOM {
  background: #f2af14;
  display: flex;
  border-radius: 10px 0px 0px 0px;
}
.statBlock {
  display: inline-flex;
}
.statsData {
  color: #f2af14;
  font-size: 55px;
  font-weight: bold;
  /* font: bold 60px Barlow; */
  /* font-family: Barlow; */
  margin-top: 12px;
}
.statsTitle {
  color: #6c6c6c;
  font-size: 10px;
  font-weight: 600;
  margin-top: 40px;
  padding-left: 10px;
  text-align: left;
}
.oomPlayerInfo {
  display: flex;
}
p.statsYear {
  color: #6c6c6c;
  font-size: 13px;
  text-align: left;
}
h2.playerName {
  color: #204232;
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 3px;
  text-align: left;
  text-transform: capitalize;
}
.natBorder {
  border-radius: 50px;
  margin-right: 30px;
}
.card-img-left {
  border-top-left-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-radius: 0px 0px 0px 0px;
  /* max-height: 315px;
  max-width: 430px; */
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  /* padding: 1.25rem; */
  padding: 45px;
  background: #f2f2f2;
  border-radius: 0px 0px 0px 0px;
}
::v-deep th.namepos {
  text-align: left !important;
}
::v-deep td.namepos {
  text-align: left !important;
}
.FullListingBtn {
  text-align: center;
  display: block;
  background: #006737;
  border: none;
  color: #fff;
  border-radius: 0;
  padding: 10px 20px;
  width: 200px;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  font-size: 11px;
  font-weight: 600;
  height: 35px;
}
.FullListingBtn > a {
  color: #fff !important;
  text-decoration: none;
  /* font: normal normal normal 12px Barlow; */
  line-height: 15px;
  display: block;
}
.FullListingBtn:hover {
  text-align: center;
  display: block;
  background: #204232;
  border: none;
  color: #fff;
  border-radius: 0;
  padding: 10px 20px;
  width: 200px;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  font-size: 11px;
  font-weight: 600;
  height: 35px;
}
::v-deep td.posnum {
  font-weight: bold;
}
.tThree {
  padding-bottom: 50px;
  margin-top: -120px;
}
a {
  color: #337ab7 !important;
  text-decoration: underline;
  background-color: transparent;
}

::v-deep thead > tr {
  color: white;
  background-color: #214232 !important;
}

::v-deep th {
  width: 15%;
  text-align: center;
}

::v-deep td {
  text-align: center;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-top: 2px solid white !important;
  font-size: 1rem;
}

.flag {
  width: 50px;
  background-color: black;
  display: inline-block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
}
::v-deep tr {
  background-color: rgba(0, 0, 0, 0.05);
}
td.posnum {
  font-weight: bold;
}
@media only screen and (max-width: 1200px) {
  .carousel-item {
    height: auto !important;
  }
  .card {
    position: relative;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem 0rem 0.25rem 0.25rem;
    display: block;
  }
  img.card-img-left {
    width: 100% !important;
    border-radius: 0px 0px 0px 0px;
  }
  .carousel-item {
    height: 100%;
  }
}
@media only screen and (max-width: 990px) {
  .FullListingBtn {
    width: 100%;
  }
  .FullListingBtn:hover {
    width: 100%;
  }
  ::v-deep .bkimage {
    position: relative;
    left: 0em;
    top: 0em;
    background-repeat: no-repeat;
    background-size: cover;
    height: 304px;
    background-position: center;
    /* border-bottom-left-radius: 10px; */
  }
  ::v-deep .card {
    width: 100%!important;
    margin-left: 0!important;
    margin-top: 0!important;
  }
  .noPad {
    margin-right: 0px;
    margin-left: 0px;
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .bkimage {
    position: relative;
    left: 0em;
    top: 0em;
    background-repeat: no-repeat;
    background-size: cover;
    height: 464px;
    background-position: center;
    /* border-bottom-left-radius: 10px; */
  }
  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    /* padding: 1.25rem; */
    padding: 20px;
    background: #f2f2f2;
    border-radius: 0px 0px 0px 0px;
  }
  h1.orderOfMerit.TitleStatLeader {
    margin-bottom: 40px;
  }
  .statLeaderBlock {
    margin-bottom: 10px !important;
  }
  .titleAndSpon {
    margin: auto;
    display: flex;
    margin-bottom: 5px;
  }
  .statsData {
    color: #f2af14;
    font-size: 40px;
    font-weight: bold;
    padding-left: 20px;
    margin-top: 12px;
  }
  .statsTitle {
    color: #6c6c6c;
    font-size: 10px;
    font-weight: 600;
    margin-top: 32px;
    padding-left: 10px;
    text-align: left;
  }
  ::v-deep .carousel-item {
    height: 800px !important;
  }
}

@media only screen and (max-width: 500px) {
  .blankPos {
    background: none;
    position: relative;
    height: 0px;
    top: 20px;
    border-radius: 0px 10px 0px 0px;
  }
}
@media only screen and (max-width: 501px) {
  ::v-deep .nomob {
    display: none;
  }
  .sectionOne {
    display: inline-flex;
  }
  .sectionTwo {
    display: inline-flex;
  }
  ::v-deep .carousel.pointer-event {
    touch-action: pan-y;
    margin-top: -80px;
  }
}
</style>

