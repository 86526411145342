<template>
  <div>
    <!-- Home Page -->
    <b-row v-if="cat === '8' && this.$route.name === 'home'" :class="postsRow" class="homePageNews">
      <b-col
        v-for="(proNews, index) in allposts.slice(0, 4)"
        :config="config"
        :key="index"
        lg="3" 
        md="6"
        sm="12"
        cols="12"
        class="bottomPad"
      >
        <b-card
          title="News"
          :config="config"
          :sub-title="proNews.title.rendered"
          :img-src="proNews.better_featured_image.source_url"
          img-alt="Image"
          img-top
          tag="article"
        >
          <b-card-text>
            <b-button :to="'/' + proNews.slug" class="postBtn HomeBTN">View Article</b-button>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-card v-if="cat === '10' && this.$route.name === 'home'" no-body class="overflow-hidden hozTop theScoreCard">
      <b-row no-gutters>
        <b-col md="5">
          <b-card-img
            class="hozImg"
            :src="allposts[0].better_featured_image.source_url"
            :config="config"
          ></b-card-img>
        </b-col>
        <b-col md="7" class="">
          <b-card-body class="hoz" :title="allposts[0].title.rendered">
              <b-card-text>
                <span class='hozText' v-html="allposts[0].excerpt.rendered"></span>
                <b-button :to="'/' + allposts[0].slug" class="postBtn">View Blog</b-button>
              </b-card-text>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>


    <!-- q-school page -->
    <b-row v-if="cat === '7' && this.$route.name === 'qschool'" :class="postsRow">
      <b-col
        v-for="(proNews, index) in allposts.slice(0, 4)"
        :key="index"
        lg="3"
        md="12"
        sm="12"
        class="bottomPad"
        :config="config"
      >
        <b-nav-item :to="'/' + proNews.slug">
          <b-card
            title="News"
            :sub-title="proNews.title.rendered"
            :img-src="proNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
            :config="config"
          >
            <b-card-text>
              <b-button :to="'/' + proNews.slug" class="postBtn q-schoolart">View Article</b-button>
            </b-card-text>
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>

    <!-- vusi-ngubeni page -->
    <b-row v-if="cat === '7' && this.$route.name === 'vusi-ngubeni'" :class="postsRow">
      <b-col
        v-for="(proNews, index) in allposts.slice(0, 4)"
        :key="index"
        lg="3"
        md="12"
        sm="12"
        class="bottomPad"
        :config="config"
      >
        <b-nav-item :to="'/' + proNews.slug">
          <b-card
            title="News"
            :sub-title="proNews.title.rendered"
            :img-src="proNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
            :config="config"
          >
            <b-card-text>
              <b-button :to="'/' + proNews.slug" class="postBtn q-schoolart">View Article</b-button>
            </b-card-text>
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>

    <!-- Papwa Swegolum page -->
    <b-row v-if="cat === '16' && this.$route.name === 'the-papwa-swegolum-class'" :class="postsRow">
      <b-col
        v-for="(proNews, index) in allposts.slice(0, 4)"
        :key="index"
        lg="3"
        md="12"
        sm="12"
        class="bottomPad"
        :config="config"
      >
        <b-nav-item :to="'/' + proNews.slug">
          <b-card
            title="News"
            :sub-title="proNews.title.rendered"
            :img-src="proNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
            :config="config"
          >
            <b-card-text>
              <b-button :to="'/' + proNews.slug" class="postBtn q-schoolart">View Article</b-button>
            </b-card-text>
          </b-card>
        </b-nav-item>
      </b-col>

    </b-row>

    <!-- Sunshine Development Tour  -->
    <b-row v-if="cat === '19' && this.$route.name === 'sunshine-development-tour'" :class="postsRow">
      <b-col
        v-for="(proNews, index) in allposts.slice(0, 4)"
        :key="index"
        lg="3"
        md="12"
        sm="12"
        class="bottomPad"
        :config="config"
      >
        <b-nav-item :to="'/' + proNews.slug">
          <b-card
            title="News"
            :sub-title="proNews.title.rendered"
            :img-src="proNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
            :config="config"
          >
            <b-card-text>
              <b-button :to="'/' + proNews.slug" class="postBtn q-schoolart">View Article</b-button>
            </b-card-text>
          </b-card>
        </b-nav-item>
      </b-col>

    </b-row>

    <!-- Blue Label News -->

    <b-row v-if="cat === '18' && feat === 'true' && this.$route.name === 'blue-label'" :class="postsRow">
      <b-col
        v-for="(proNews, index) in allposts.slice(0, 2)"
        :key="index"
        :config="config"
        lg="6"
        md="6"
        class="bottomPad"
      >
        <b-nav-item :to="'/' + proNews.slug">
          <b-card
            title="News"
            :sub-title="proNews.title.rendered"
            :img-src="proNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
            class="NewsPageImage"
            :config="config"
          >
            <b-card-text>
              <span class='hozText' v-html="proNews.excerpt.rendered"></span>
              <b-button :to="'/' + proNews.slug" class="postBtn">View Article</b-button>
            </b-card-text>
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>

    <b-row v-if="cat === '18' && feat === 'false' && this.$route.name === 'blue-label'" :class="postsRow" class="overflowRight">
      <b-col
        v-for="(proNews, index) in allposts.slice(3, 6)"
        :key="index"
        lg="4"
        md="6"
        class="bottomPad overflowRightInner"
        :config="config"
      >
        <b-nav-item :to="'/' + proNews.slug">
          <b-card
            title="News"
            :sub-title="proNews.title.rendered"
            :img-src="proNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
            :config="config"
            class="cardBody"
          >
            <b-card-text>
              <b-button :to="'/' + proNews.slug" class="postBtn HomeViewArticle">View Article</b-button>
            </b-card-text>
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>    

    <!-- News Page -->

    <b-row v-if="cat === '8' && feat === 'true' && this.$route.name === 'News'" :class="postsRow">
      <b-col
        v-for="(proNews, index) in allposts.slice(0, 2)"
        :key="index"
        :config="config"
        lg="6"
        md="6"
        class="bottomPad"
      >
        <b-nav-item :to="'/' + proNews.slug">
          <b-card
            title="News"
            :sub-title="proNews.title.rendered"
            :img-src="proNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
            class="NewsPageImage"
            :config="config"
          >
            <b-card-text>
              <span class='hozText' v-html="proNews.excerpt.rendered"></span>
              <b-button :to="'/' + proNews.slug" class="postBtn">View Article</b-button>
            </b-card-text>
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>

    <b-row v-if="cat === '8' && feat === 'false' && this.$route.name === 'News'" :class="postsRow" class="overflowRight">
      <b-col
        v-for="(proNews, index) in allposts.slice(3, 6)"
        :key="index"
        lg="4"
        md="6"
        class="bottomPad overflowRightInner"
        :config="config"
      >
        <b-nav-item :to="'/' + proNews.slug">
          <b-card
            title="News"
            :sub-title="proNews.title.rendered"
            :img-src="proNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
            :config="config"
            class="cardBody"
          >
            <b-card-text>
              <b-button :to="'/' + proNews.slug" class="postBtn HomeViewArticle">View Article</b-button>
            </b-card-text>
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>

    <b-row v-if="cat === '5' && this.$route.name === 'News'" :class="postsRow" class="overflowRight">
      <b-col
        v-for="(proNews, index) in allposts.slice(0, 3)"
        :key="index"
        lg="4"
        md="6"
        class="bottomPad overflowRightInner"
        :config="config"
      >
        <b-nav-item :to="'/' + proNews.slug">
          <b-card
            title="News"
            :sub-title="proNews.title.rendered"
            :img-src="proNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
            :config="config"
            class="cardBody"
          >
            <b-card-text>
              <b-button :to="'/' + proNews.slug" class="postBtn">View Article</b-button>
            </b-card-text>
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>

    <b-row v-if="cat === '4' && this.$route.name === 'News'" :class="postsRow" class="overflowRight">
      <b-col
        v-for="(proNews, index) in allposts.slice(0, 3)"
        :key="index"
        lg="4"
        md="6"
        class="bottomPad overflowRightInner"
        :config="config"
      >
        <b-nav-item :to="'/' + proNews.slug">
          <b-card
            title="News"
            :sub-title="proNews.title.rendered"
            :img-src="proNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
            :config="config"
            class="cardBody"
          >
            <b-card-text>
              <b-button :to="'/' + proNews.slug" class="postBtn">View Article</b-button>
            </b-card-text>
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>


    <!-- The Score -->

    <!-- <b-card v-if="cat === '10' && this.$route.name === 'thescore' && feat === 'true'" no-body class="overflow-hidden hozTop thescoreTop" >
      <b-row v-for="(proNews, index) in allposts.slice(0,1)"
        :key="index"
        >
        <b-col md="5">
          <b-card-img
            class="hozImg"
            :src="proNews.better_featured_image.media_details.sizes.large.source_url"
            :config="config"
          ></b-card-img>
        </b-col>
        <b-col md="7" class="hozCol scorePage">
          <b-card-body class="hoz" :title="allposts[0].title.rendered">
            <b-card-text>
              <span class='hozText' v-html="allposts[0].excerpt.rendered"></span>
              <b-button :to="'/' + proNews.slug" class="postBtn">View Blog</b-button>
            </b-card-text>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card> -->

    <b-row v-if="cat === '10' && this.$route.name === 'thescore' && feat === 'false'" :class="postsRow">
      <b-col
        v-for="(proNews, index) in allposts"
        :key="index"
        lg="6"
        md="6"
        class="bottomPad"
        :config="config"
      >
        <b-nav-item :to="'/the-score/' + proNews.slug">
          <b-card
            title="Player Blog"
            :sub-title="proNews.title.rendered"
            :img-src="proNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
            :config="config"
            class="scoreCard"
          >
            <b-card-text>
              <span class='hozText' v-html="proNews.excerpt.rendered"></span>
              <b-button :to="'/' + proNews.slug" class="blogButton">View Blog</b-button>
            </b-card-text>
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>


    <!-- More News Page -->
    
    <b-row v-if="cat === '10,8,5,6' && this.$route.name === 'morenews'" :class="postsRow">
      <b-col
        v-for="(proNews, index) in allposts"
        :key="index"
        lg="4"
        md="6"
        class="bottomPad"
        :config="config"
      >
        <b-nav-item v-if="proNews.better_featured_image.source_url.length > 0">
          <b-card
            title="All News"
            :sub-title="proNews.title.rendered"
            :img-src="proNews.better_featured_image.source_url"
            img-alt="Image"
            img-top
            tag="article"
            :config="config"
          >
            <b-card-text>
              <b-button :to="'/' + proNews.slug" class="postBtn">View Article</b-button>
            </b-card-text>
          </b-card>
        </b-nav-item>
        <b-nav-item v-else>
          <b-card
            title="All News"
            :sub-title="proNews.title.rendered"
            img-src="https://updated.sunshinetour.com/wp-content/uploads/2021/10/sunshine-tour-welcomes-back-limited-number-of-fans-at-tournaments-2.png"
            img-alt="Image"
            img-top
            tag="article"
            :config="config"
          >
            <b-card-text>
              <b-button :to="'/' + proNews.slug" class="postBtn">View Article</b-button>
            </b-card-text>
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "interNews",
  props: ["cat", 'feat', 'config'],
  data() {
    return {
      allposts: [],
      postsRow: "posts",
      postImg: "postImg",
    };
  },
  methods: {
    newLabel: function(label) {
      if (this.cat === "8") label = "News";
      return label;
    },
  },
  created() {
    const json = "wp/v2/posts?categories=" + this.cat;
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.allposts = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
a.btn.postBtn.q-schoolart.btn-secondary {
  line-height: 23px;
}
/* .q-schoolart {
  line-height: 23px;
} */
a.btn.postBtn.HomeBTN.btn-secondary {
  border: 0px solid transparent;
  background: #F2AF14;
  color: #fff!important;
  font-size: 11px;
}
a.btn.postBtn.btn-secondary {
  border: 1px solid #006737;
  background: #006737;
  color: #fff!important;
  font-size: 11px;
}
a.btn.postBtn.btn-secondary:hover {
  border: 1px solid #E0E0E0;
  background: #204232!important;
  color: #fff!important;
  font-size: 11px;
}
article.card.scoreCard > .card-img-top {
  max-height: initial;
}
.blogButton {
  margin: auto;
  background: #006737;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 200px;
  font-size: 11px;
  text-transform: capitalize;
  border: 1px solid #E0E0E0;
  font-weight: bold;
}
.blogButton:hover {
  margin: auto;
  background: #204232;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 200px;
  font-size: 11px;
  text-transform: capitalize;
  border: 1px solid #E0E0E0;
  font-weight: bold;
}
.overflowRightInner{
  display: inline-block;
  float: none;
}
.overflowRight{
  overflow-x: auto;
  white-space: nowrap;
  display: block;
}
article.card.cardBody > .card-body > .card-subtitle {
  margin-bottom: 20px !important;
}
::v-deep article.card.cardBody > .card-body {
  box-shadow: 0px 0px 0px #00000029;
  padding-left: 0;
  padding-right: 0;
}
::v-deep .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  box-shadow: 0px 3px 6px #00000029;
}
::v-deep article.card.NewsPageImage > img {
  max-height: 400px;
  min-height: 400px;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  /* border-radius: 10px; */
  border-radius: 10px 10px 0px 0px;
}
.hozCol.scorePage.col-md-7 {
  padding-right: 50px;
}
.thescoreTop {
  border-radius: 20px!important;
}
.scorePage > div {
  padding: 0rem;
}
img.card-img-top {
  max-height: 180px;
  min-height: 180px;
  border-radius: 0px;
  object-fit: cover;
  object-position: top;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.theScoreCard {
  border-radius: 20px!important;
}
.card-text:last-child {
  margin-bottom: 0;
  color: #000;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}
span.postText {
  font-size: 12pt;
}
h1.postsTitle {
  font-size: 20pt;
  padding-top: 15px;
  padding-bottom: 15px;
  white-space: nowrap;
  width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
 
}
.overflowImage {
  overflow: hidden;
  height: 278px;
}

.card-title {
  font-weight: 100;
  letter-spacing: -0.5px;
  color: #9aa5ac;
  margin-top: -2px;
  margin-bottom: -5px;
  font-size: 11px;
  color: #f2af15;
  text-transform: capitalize;
}
.feat > .card-body > .card-subtitle {
  font-size: 23pt !important;
}
.card-subtitle {
  color: #6C6C6C !important;
  /* font-weight: 300; */
  font-weight: 700;
  font-size: 13px;
  background-color: #fff;
  margin-top: 13px;
  margin-bottom: 5px !important;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.viewAll {
  position: relative;
  top: 1em;
  color: #000;
  font-weight: 600 !important;
  font-size: 14px;
  padding-left: 15px;
}
.noRightPad {
  padding-right: 0;
  padding-left: 5px;
}
.bottomPad {
  padding-bottom: 20px;
}
.nav-link {
  padding: 0;
}

.mobShow {
  display: none;
}

.card-text {
  padding-top: 7px;
}
.postBtn {
  text-align: center;
  display: block;
  margin: auto;
  background: #006737;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 100%;
  font-size: 11px;
  text-transform: capitalize;
  border: 1px solid #E0E0E0;
  font-weight: bold;
  line-height: 22px;
  /* height: 35px; */
}
.postBtn:hover{
  background-color: #204232;
  color: #fff!important;
}
.hoz > h4 {
  color: #046532;
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 15px;
}
.hozCol {
  padding: 1.25rem;
}
.hozImg {
  border-radius: 10px 0 0 10px;
}
.hozTop {
  border-radius: 10px 0 0 10px;
}
::v-deep .hozText>p{
  margin-bottom: 25px;
  color: #6C6C6C;
  font-size: 13px;
}
@media only screen and (max-width: 480px) {
  #mobilePost {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .feat {
    height: 485px !important;
  }
  .featMob {
    height: 350px !important;
  }
  img.card-img-top {
    max-height: inherit;
    min-height: 263px!important;
    border-radius: 0px;
  }
  .overflowRight {
    overflow-x: auto;
    white-space: nowrap;
    display: inline-flex;
    width: 108%;
  }
}
@media only screen and (max-width: 1165px) {
  #mobilePost:last-child {
    padding-right: 15px;
    margin-top: 2em;
  }
  div#mobilePost {
    margin-top: 1em;
  }
}

@media only screen and (max-width: 990px) {
  .homePageNews {
    flex-wrap: nowrap;
    overflow: scroll;
  }
  img.card-img-top {
    min-height: 219px;
    border-radius: 0px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
  }
}

@media only screen and (max-width: 768px) {
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0px;
  }
  .hozImg {
    border-radius: 10px 0 0 0px;
  }
  .hozCol.scorePage.col-md-7 {
    padding-right: 30px;
    padding-left: 30px;
  }
  img.card-img-top {
    max-height: 100px;
    /* min-height: auto; */
    border-radius: 0px;
  }
  .featMob {
    height: 621px;
  }
  .noRightPad {
    padding: 15px;
  }
  .mobShow {
    display: block;
  }
.blogButton {
  margin: auto;
  background: #006737;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 100%;
  font-size: 11px;
  text-transform: capitalize;
  border: 1px solid #E0E0E0;
  font-weight: bold;
}
.blogButton:hover {
  margin: auto;
  background: #f2af15;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 100%;
  font-size: 11px;
  text-transform: capitalize;
  border: 1px solid #E0E0E0;
  font-weight: bold;
}
}

</style>
